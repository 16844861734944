import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ScrollContainer from 'react-indiana-drag-scroll'
import {
  ContentGroup,
  Layout, Loading, SEO, Link,
} from '../../ui'
import EmptyScreen from '../../ui/empty-screen'
import DineInBanner from '../../ui/dine-in-banner'
import Image from '../../ui/image'
import useI18n from '../../hook/use-i18n'
import { useThemeConfig } from '../../hook/use-theme-config'
import useStyles from './categories-style'
import Button from '../../ui/button'
import { combineClassNames } from '../../helpers'

const Item = (item) => {
  const { currentLocale } = useI18n()
  const { getConfig } = useThemeConfig()
  const [displayName, setDisplayName] = useState(false)
  const styles = useStyles()
  const locale = _.get(currentLocale, 'locale')

  // possible values: [always, noImage, never]
  const categoryNameDisplayApproach = getConfig(
    'config.pages.categories.categoryList.nameDisplayApproach',
    'noImage',
  )

  const getCategoryLinkProps = (category) => {
    const code = _.get(category, 'code')
    const childrenCategories = _.get(category, 'children', [])
    const params = _.isEmpty(childrenCategories)
      ? { category_code_eq: code }
      : {
        category_code_eq: _.get(childrenCategories, '0.code'),
      }
    return {
      to: '/products/',
      params,
    }
  }

  const getCategoryImage = (category) => (
    _.get(category, `meta.thumbnail.${locale}`) // image for current locale (raw)
    || _.get(category, `meta.thumbnail.${_.camelCase(locale)}`) // image for current locale (camelCased)
    || _.get(category, 'meta.thumbnail.default') // image for all locale
    || _.get(category, 'image.versions.ecomThumbMedium') // fallback to updaterd thumbnail
  )

  useEffect(() => {
    switch (categoryNameDisplayApproach) {
      case 'always':
        setDisplayName(true)
        break;
      case 'never':
        setDisplayName(false)
        break;
      default:
        break;
    }
  }, [categoryNameDisplayApproach])

  return (
    <li className={styles.item}>
      <Link
        gatsbyLink
        {...getCategoryLinkProps(item)}
        className={
          combineClassNames([
            styles.itemLink,
            displayName && styles.itemLinkWithName,
          ])
        }
        title={displayName && item.name}
        aria-label={item.name}
      >
        <Image
          className={styles.itemImage}
          placeholderClassName={styles.itemImagePlaceholder}
          src={getCategoryImage(item)}
          alt={item.name}
          onChange={(isPlaceholder) => {
            if (categoryNameDisplayApproach === 'noImage') {
              setDisplayName(isPlaceholder)
            }
          }}
        />
      </Link>
    </li>
  )
}

function CategoriesView({
  // home page content group code
  // the page will be ready after loading content group data is end
  bannerContentGroupProps,
  categories,
  departments,
  loading,
  seoTitle,
  onDepartmentClick,
  selectedDepartment,
  // showDineInOrderPlaced,
}) {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <Layout
      HeaderBottomComponent={<DineInBanner />}
    >
      {/* passing page specific data for SEO */}
      <SEO title={seoTitle} />
      {
        !loading
          ? (
            <div
              className={styles.containerFluid}
            >
              <div
                className={styles.container}
              >
                {
                  bannerContentGroupProps.code && (
                    <ContentGroup
                      {...bannerContentGroupProps}
                    />
                  )
                }
                {
                  _.size(departments) > 1 && (
                    <nav className={styles.departmentsMenuContainer}>
                      <ScrollContainer
                        vertical={false}
                        className={styles.departmentsMenu}
                        component="ul"
                      >
                        {
                          _.map(departments, ({ code, name }) => (
                            <li
                              key={`department_${code}`}
                              className={
                                combineClassNames([
                                  styles.departmentsMenuItem,
                                  selectedDepartment === code && styles.departmentsMenuItemActive,
                                ])
                              }
                            >
                              <Button
                                className={
                                  combineClassNames([
                                    styles.departmentsMenuItemInner,
                                    selectedDepartment === code
                                      && styles.departmentsMenuItemInnerActive,
                                  ])
                                }
                                inline
                                onClick={() => onDepartmentClick(code)}
                              >
                                {name}
                              </Button>
                            </li>
                          ))
                        }
                      </ScrollContainer>
                    </nav>
                  )
                }
                {
                  _.isEmpty(categories)
                    ? (
                      <EmptyScreen
                        // title={t('screens.categories.empty.title')}
                        message={t('screens.categories.empty.message')}
                      />
                    )
                    : (
                      <nav>
                        <ul className={styles.list}>
                          {
                            _.map(categories, (item) => (
                              <Item
                                key={item.code}
                                {...item}
                              />
                            ))
                          }
                        </ul>
                      </nav>
                    )
                }
              </div>
            </div>
          )
          : (
            <Loading />
          )
      }
    </Layout>
  )
}

export default CategoriesView
